var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("service_row " + (_vm.highlight ? 'highlight' : '')),style:(("column-gap: " + (_vm.displayFullServiceInfo ? '1' : '.5') + "rem;"))},[(_vm.fieldsToDisplay.includes(_vm.constants.SERVICE_ROW_FIELDS.VISIBLE))?_c('div',{staticStyle:{"width":"5%"}},[_vm._v(" "+_vm._s(_vm.parseNumberToTrueFalse(_vm.service.visible))+" ")]):_vm._e(),_c('div',{staticStyle:{"width":"5%"}},[_vm._v(" "+_vm._s(_vm.service.day || "-")+" ")]),_c('div',{staticClass:"service_dates",style:(("width: " + (_vm.displayFullServiceInfo ? '15' : '15') + "%;"))},[_c('p',[_vm._v(" "+_vm._s(_vm.helpers.format(_vm.service.start_date, _vm.datesFormat))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.validatedServiceEndDate)+" ")])]),_c('div',{style:(("width: " + (_vm.displayFullServiceInfo ? '15' : '20') + "%;"))},[_c('p',{staticClass:"service_row_country m-0"},[_vm._v(_vm._s(_vm.country))]),_c('p',{staticClass:"service_row_destination"},[_vm._v(_vm._s(_vm.destination))])]),_c('div',{staticClass:"service_row_card",style:(("width: " + (_vm.displayFullServiceInfo ? '30' : '40') + "%;"))},[_c('header',[_c('b-icon',{staticClass:"card-icon",attrs:{"icon":_vm.helpers.showIconByServiceType(_vm.service.id_service_type),"alt":"icon"}}),_c('span',[_vm._v(_vm._s(_vm.service.code))])],1),_c('TextWithHoverDetail',{attrs:{"message":_vm.validatedServiceName},scopedSlots:_vm._u([{key:"baseText",fn:function(){return [(
            _vm.service.optional === _vm.constants.SERVICE_OPTIONAL_TYPES.CONFIRMED
          )?_c('ServiceTag',{attrs:{"type":"dark","message":"Optional Experience Confirmed"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('IconCheckCircle',{staticStyle:{"height":"0.6rem","width":"0.8rem"}})]},proxy:true},{key:"message",fn:function(){return [_c('span',[_vm._v(" Optional Experience Confirmed")])]},proxy:true}],null,false,4255678713)}):(_vm.service.optional)?_c('ServiceTag',{attrs:{"type":"light","message":"Optional Experience"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('IconGrowthArrow',{staticStyle:{"height":"0.6rem"}})]},proxy:true},{key:"message",fn:function(){return [_c('span',[_vm._v(" Optional Experience ")])]},proxy:true}])}):_vm._e(),_c('p',[_vm._v(_vm._s(_vm.validatedServiceName))])]},proxy:true}])}),(_vm.serviceHasRooms)?_c('div',{staticClass:"tags_container"},_vm._l((_vm.service.service_price),function(room){return _c('span',{key:room.id,staticStyle:{"position":"relative"}},[(room.room_qty > 1)?_c('span',{staticClass:"tag_alert"},[_vm._v(" "+_vm._s(room.room_qty)+" ")]):_vm._e(),_c('ServiceTag',{attrs:{"message":room.romm_name},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(room.romm_name)+" ")])]},proxy:true}],null,true)})],1)}),0):_vm._e()],1),(_vm.displayFullServiceInfo)?_c('div',{staticStyle:{"width":"5%"}},[_vm._v(" "+_vm._s(_vm.findLevelByID(_vm.service.level))+" ")]):_vm._e(),(_vm.displayFullServiceInfo)?_c('div',{staticStyle:{"width":"5%"}},[_vm._v(" "+_vm._s(_vm.service.num_pax)+" ")]):_vm._e(),(_vm.displayFullServiceInfo)?_c('div',{staticClass:"color-7",staticStyle:{"width":"5%"}},[_vm._v(" $ "+_vm._s(_vm.service.cost || 0)+" ")]):_vm._e(),(
      _vm.service.isPriceEdited &&
      _vm.fieldsToDisplay.includes(_vm.constants.SERVICE_ROW_FIELDS.PRICE)
    )?_c('div',{staticClass:"is__warning",staticStyle:{"width":"15%"}},[_vm._v(" $ "+_vm._s(_vm.service.price || 0)+" "),(_vm.service.isPriceEdited)?_c('IconWarningCircle',{staticClass:"ml-1 mb-1",staticStyle:{"fill":"#ff9316"}}):_vm._e()],1):_vm._e(),(
      _vm.displayFullServiceInfo ||
      (_vm.fieldsToDisplay.includes(_vm.constants.SERVICE_ROW_FIELDS.PRICE) &&
        !_vm.service.isPriceEdited)
    )?_c('div',{staticClass:"color-up",staticStyle:{"width":"15%"}},[_vm._v(" $ "+_vm._s(_vm.service.price || 0)+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }